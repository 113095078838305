<template>
  <div>
    <relation-table
      :entityKey="entityKey"
      :entityIdKey="entityIdKey"
      :relationKey="relationKey"
      :noEdit="!userHasWritePermission"
      :translations="TRANSLATIONS.table"
    >
      <template #title>{{ TRANSLATIONS.relationtable.title }}</template>
      <template #killButton>{{ TRANSLATIONS.relationtable.killrelationbutton }}</template>

      <template #modalTitle>{{ TRANSLATIONS.relationtable.modalkillrelationtitle }}</template>
      <template #modalBody="data">
        <p v-html="$T(TRANSLATIONS.relationtable.modalkillrelationbody, {
          username: `<span class='badge badge-info'>${data.item.lastname} ${data.item.firstname}</span>`,
          location: `<span class='badge badge-light'>${parent.name}</span>`
        })"/>
      </template>
      <template #modalAbortButton>{{ TRANSLATIONS.relationtable.modalkillrelationabortbutton }}</template>
      <template #modalConfirmButton>{{ TRANSLATIONS.relationtable.modalkillrelationconfirmbutton }}</template>
    </relation-table>

    <template v-if="userHasWritePermission">
      <hr/>

      <relation-table
        mode="relationrequest"
        :entityKey="entityKey"
        :entityIdKey="entityIdKey"
        :relationKey="relationKey"
        :translations="TRANSLATIONS.table"
      >
        <template #title>{{ TRANSLATIONS.relationrequesttable.title }}</template>
        <template #declineButton>{{ TRANSLATIONS.relationrequesttable.declinerelationbutton }}</template>
        <template #acceptButton>{{ TRANSLATIONS.relationrequesttable.acceptrelationbutton }}</template>

        <template #modalTitle>{{ TRANSLATIONS.relationrequesttable.modaldeclinerelationtitle }}</template>
        <template #modalBody="data">
          <p v-html="$T(TRANSLATIONS.relationrequesttable.modaldeclinerelationbody, {
            username: `<span class='badge badge-info'>${data.item.lastname} ${data.item.firstname}</span>`
          })"/>
        </template>
        <template #modalAbortButton>{{ TRANSLATIONS.relationrequesttable.modaldeclinerelationabortbutton }}</template>
        <template #modalConfirmButton>{{ TRANSLATIONS.relationrequesttable.modaldeclinerelationconfirmbutton }}</template>
      </relation-table>

      <template v-if="parentId">
        <hr/>

        <invitation-table
          :entityId="parentId"
          :translations="Object.assign({}, TRANSLATIONS.table, TRANSLATIONS.invitationtable)"
        >
          <template #title>{{ TRANSLATIONS.invitationtable.title }}</template>
          <template #killButton>{{ TRANSLATIONS.invitationtable.killinvitationbutton }}</template>

          <template #modalTitle>{{ TRANSLATIONS.invitationtable.modalkillinvitationtitle }}</template>
          <template #modalBody="data">
            <p v-html="$T(TRANSLATIONS.invitationtable.modalkillinvitationbody, {
              email: `<span class='badge badge-info'>${data.item.email}</span>`
            })"/>
          </template>
          <template #modalAbortButton>{{ TRANSLATIONS.invitationtable.modalkillinvitationabortbutton }}</template>
          <template #modalConfirmButton>{{ TRANSLATIONS.invitationtable.modalkillinvitationconfirmbutton }}</template>
        </invitation-table>

        <hr/>

        <invite-form
          :entityId="parentId"
          :initialValues="{ language: $store.getters['gui/getLanguage'] }"
          :translations="TRANSLATIONS.inviteform"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { USER_PERMISSIONS } from '@/assets/js/config/server'

import string from '@/assets/js/helper/string'

import RelationTable from '@/components/authenticated/Locations/RelationTable'
import InvitationTable from '@/components/authenticated/Locations/InvitationTable'
import InviteForm from '@/components/authenticated/Locations/InviteForm'

const PARENT_KEY = 'locations'
const PARENT_ID = /(?:\/)([a-zA-Z0-9]{8}-([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{12})$/g.exec(window.location.pathname)[1]
const ENTITY_KEY = 'users'
const RELATION_KEY = 'userlocations'

export default {
  name: 'Locations.Users',
  components: {
    RelationTable,
    InvitationTable,
    InviteForm
  },
  data () {
    return {
      parentKey: PARENT_KEY,
      parentId: PARENT_ID,
      parentIdKey: `${string.singularize(PARENT_KEY)}Id`,
      entityKey: ENTITY_KEY,
      entityIdKey: `${string.singularize(ENTITY_KEY)}Id`,
      relationKey: RELATION_KEY
    }
  },
  computed: {
    userHasWritePermission () {
      const userId = this.$store.getters['profile/getUserId']
      const userRoleOnLocation = (this.$store.getters['userlocations/getUnwrapped'].find(l => l[this.parentIdKey] === this.parentId && l[this.entityIdKey] === userId) || {}).role
      const userPermissions = (Object.values(USER_PERMISSIONS).find(p => p.role === userRoleOnLocation) || {}).permissions || []

      return userPermissions.includes('write')
    },
    parent () {
      return this.$store.getters[`${this.parentKey}/getEntityUnwrapped`](this.parentId)
    }
  },
  methods: {
    getEntities () {
      this.$store.dispatch(`${this.parentKey}/getEntity`, this.parentId)
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: { name: this.parentKey, id: this.parentId } })
    }
  },
  created () {
    this.getEntities()
  }
}
</script>

<style lang="scss"></style>
