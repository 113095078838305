var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('relation-table',{attrs:{"entityKey":_vm.entityKey,"entityIdKey":_vm.entityIdKey,"relationKey":_vm.relationKey,"noEdit":!_vm.userHasWritePermission,"translations":_vm.TRANSLATIONS.table},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationtable.title))]},proxy:true},{key:"killButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationtable.killrelationbutton))]},proxy:true},{key:"modalTitle",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationtable.modalkillrelationtitle))]},proxy:true},{key:"modalBody",fn:function(data){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.$T(_vm.TRANSLATIONS.relationtable.modalkillrelationbody, {
        username: ("<span class='badge badge-info'>" + (data.item.lastname) + " " + (data.item.firstname) + "</span>"),
        location: ("<span class='badge badge-light'>" + (_vm.parent.name) + "</span>")
      }))}})]}},{key:"modalAbortButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationtable.modalkillrelationabortbutton))]},proxy:true},{key:"modalConfirmButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationtable.modalkillrelationconfirmbutton))]},proxy:true}])}),(_vm.userHasWritePermission)?[_c('hr'),_c('relation-table',{attrs:{"mode":"relationrequest","entityKey":_vm.entityKey,"entityIdKey":_vm.entityIdKey,"relationKey":_vm.relationKey,"translations":_vm.TRANSLATIONS.table},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.title))]},proxy:true},{key:"declineButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.declinerelationbutton))]},proxy:true},{key:"acceptButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.acceptrelationbutton))]},proxy:true},{key:"modalTitle",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.modaldeclinerelationtitle))]},proxy:true},{key:"modalBody",fn:function(data){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.$T(_vm.TRANSLATIONS.relationrequesttable.modaldeclinerelationbody, {
          username: ("<span class='badge badge-info'>" + (data.item.lastname) + " " + (data.item.firstname) + "</span>")
        }))}})]}},{key:"modalAbortButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.modaldeclinerelationabortbutton))]},proxy:true},{key:"modalConfirmButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.relationrequesttable.modaldeclinerelationconfirmbutton))]},proxy:true}],null,false,3768104359)}),(_vm.parentId)?[_c('hr'),_c('invitation-table',{attrs:{"entityId":_vm.parentId,"translations":Object.assign({}, _vm.TRANSLATIONS.table, _vm.TRANSLATIONS.invitationtable)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.invitationtable.title))]},proxy:true},{key:"killButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.invitationtable.killinvitationbutton))]},proxy:true},{key:"modalTitle",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.invitationtable.modalkillinvitationtitle))]},proxy:true},{key:"modalBody",fn:function(data){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.$T(_vm.TRANSLATIONS.invitationtable.modalkillinvitationbody, {
            email: ("<span class='badge badge-info'>" + (data.item.email) + "</span>")
          }))}})]}},{key:"modalAbortButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.invitationtable.modalkillinvitationabortbutton))]},proxy:true},{key:"modalConfirmButton",fn:function(){return [_vm._v(_vm._s(_vm.TRANSLATIONS.invitationtable.modalkillinvitationconfirmbutton))]},proxy:true}],null,false,1164054751)}),_c('hr'),_c('invite-form',{attrs:{"entityId":_vm.parentId,"initialValues":{ language: _vm.$store.getters['gui/getLanguage'] },"translations":_vm.TRANSLATIONS.inviteform}})]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }