<template>
  <div>
    <b-row align-v="center" align-h="between">
      <b-col cols="auto">
        <h2 class="h4"><slot name="title"/></h2>
      </b-col>

      <b-col cols="auto">
        <!-- <control-input :id="table.search.id" v-model="table.search.value" :placeholder="table.search.placeholder" size="sm">
          <template #append>
            <b-button variant="dark" :disabled="!table.search.value" @click="resetTableSearch"><b-icon icon="reset"/></b-button>
          </template>
        </control-input> -->
      </b-col>
    </b-row>

    <b-table
      :busy="is.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      head-variant="dark"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader inline scale="4"/>
          <div>{{ $T('Busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $T('Empty') }}</p>
      </template>

      <template #cell(created)="data">
        <timeago :datetime="data.value || data.item.created" :converterOptions="{ forceTextFormat: true }" auto-update/>
      </template>

      <template #cell($kill)="data">
        <b-button variant="warning" size="sm" class="text-nowrap" @click="openModal('kill', data.item)"><b-icon icon="trash"/> <slot name="killButton"/></b-button>
      </template>
    </b-table>

    <b-modal
      :id="confirmationModal.id"
      v-model="confirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
      title-tag="h4"
      title-class="align-self-center"
      hide-footer
    >
      <template #modal-header-close><b-icon icon="close" scale="0.6"/></template>
      <template #modal-title><slot name="modalTitle"/></template>

      <template v-if="confirmationModal.show">
        <div>
          <slot name="modalBody" :item="confirmationModal.item"/>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <div class="btn-list">
            <b-button variant="light" size="sm" @click="closeModal"><slot name="modalAbortButton"/></b-button>
            <b-button variant="danger" size="sm" @click="killInvitation"><b-icon icon="trash"/> <slot name="modalConfirmButton"/></b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/assets/js/config/client'

import Table from '@/assets/js/helper/table'

export default {
  name: 'Locations.Users.InvitationTable',
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      table: {
        id: this.$options.name,
        options: {
          fields: {
            includes: ['$kill'],
            excludes: [],
            sorting: ['email', 'invitedBy', 'created', '$kill'],
            sortable: [CLASS_TABLE_FIELD_KEY_ALL],
            label: {
              email: this.$T('EmailLabel'),
              invitedBy: this.$T('InvitedByLabel'),
              created: this.$T('CreatedLabel'),
              $kill: ''
            },
            variant: {},
            class: {
              $kill: `cell-min-${this.$root.mobile.up.key} cell-align-right`
            },
            thClass: {},
            tdClass: {}
          }
        }
      },
      confirmationModal: {
        id: `${this.$options.name}_ConfirmationModal`,
        key: null,
        show: false,
        item: null
      }
    }
  },
  computed: {
    location () {
      return this.$store.getters['locations/getEntity'](this.entityId)
    },
    is () {
      return this.$store.getters['userlocations/is']
    },
    definition () {
      return this.$store.getters['userlocations/definition']
    },
    invitations () {
      return this.$store.getters['userlocations/getInvitations']
    },
    tableEntities () {
      return this.invitations
        .map(INVITATION => {
          return {
            _invitation: INVITATION,
            id: INVITATION.id,
            email: INVITATION.email,
            invitedBy: INVITATION.invitedBy,
            created: INVITATION.created
          }
        })
    },
    tableData () {
      return new Table(this.tableEntities, this.table.options.fields)
    }
  },
  methods: {
    getInvitations () {
      if (this.entityId) {
        this.$store.dispatch('userlocations/getInvitations', this.entityId)
          .then(response => {
            this.setTableLabels()
          })
      }
    },
    setTableLabels () {
      if (this.definition.properties.length > 0) {
        this.table.options.fields.label = Object.assign(this.table.options.fields.label, this.definition.properties.reduce((labels, p) => Object.assign(labels, { [p.name]: p.translations[this.$store.getters['gui/getLanguage']].name }), {}))
        this.$set(this.table.options.fields, 'labelsByDefinitionCreated', true)
      }
    },
    killInvitation () {
      this.$store.dispatch('userlocations/removeInvitation', {
        locationId: this.entityId,
        invitation: this.confirmationModal.item
      })
      this.closeModal()
    },
    openModal (key = '', item = {}) {
      this.confirmationModal.key = key
      this.confirmationModal.item = item
      this.confirmationModal.show = true
    },
    closeModal () {
      this.confirmationModal.key = null
      this.confirmationModal.show = false
      this.confirmationModal.item = null
    }
  },
  created () {
    this.getInvitations()
  },
  watch: {
    entityId () {
      this.getInvitations()
    },
    definition: {
      deep: true,
      handler () {
        this.setTableLabels()
      }
    }
  }
}
</script>

<style lang="scss"></style>
