<template>
  <div>
    <h2 class="h4" v-html="$T('Title')"/>

    <formular
      :isBusy="is.loading"
      inline
      inlineAlignment="start"
      :controlDefinition="formDefinition"
      :controlValues="formValues"
      :buttonSizes="{ submit: 'sm', reset: 'sm' }"
      @formular:submit="onSubmit"
    >
      <template #reset><b-icon icon="reset"/> {{ $T('ResetButton') }}</template>
      <template #submit><b-icon icon="plus"/> {{ $T('SubmitButton') }}</template>
    </formular>
  </div>
</template>

<script>
import Formular from '@/components/form/Formular'

const ENTITY_KEY = 'users'
const RELATION_KEY = 'userlocations'
const INVITE_PROPERTIES = ['email', 'language']

export default {
  name: 'Locations.Users.InviteForm',
  components: {
    Formular
  },
  props: {
    entityId: {
      type: String,
      required: true
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${ENTITY_KEY}/is`]
    },
    definition () {
      return this.$store.getters[`${ENTITY_KEY}/definition`]
    },
    formDefinition () {
      return Object.assign({}, this.definition, {
        properties: INVITE_PROPERTIES
          .filter(pKey => this.definition.$properties[pKey] !== undefined)
          .map(pKey => {
            const propertyDefinition = this.definition.$properties[pKey]
            propertyDefinition.settings.size = 'sm'

            return propertyDefinition
          })
      })
    },
    formValues () {
      return Object.keys(this.initialValues)
        .filter(vKey => this.definition.$properties[vKey] !== undefined)
        .reduce((values, vKey) => Object.assign(values, { [vKey]: this.initialValues[vKey] || null }), {})
    }
  },
  methods: {
    onSubmit (formData) {
      this.$store.dispatch(`${RELATION_KEY}/createInvitation`, {
        locationId: this.entityId,
        invite: formData.controls
      })
    }
  }
}
</script>

<style lang="scss"></style>
