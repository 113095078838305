<template>
  <div>
    <div>
      <b-row align-v="center" align-h="between">
        <b-col cols="auto">
          <h2 class="h4"><slot name="title"/></h2>
        </b-col>

        <b-col cols="auto">
          <control-input :id="table.search.id" v-model="table.search.value" :placeholder="$T('SearchPlaceholder')" size="sm">
            <template #append>
              <b-button variant="dark" :disabled="!table.search.value" @click="resetTableSearch"><b-icon icon="reset"/></b-button>
            </template>
          </control-input>
        </b-col>
      </b-row>
    </div>

    <b-table
      :busy="is.loading || relationsIs.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      :filter="table.search.value"
      :filter-included-fields="table.search.on"
      head-variant="dark"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
      :tbody-tr-class="table.helper.rowClass"
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader inline scale="4"/>
          <div>{{ $T('Busy') }}</div>
        </div>
      </template>

      <template #empty>
        <p class="my-2 text-center">{{ $T('Empty') }}</p>
      </template>

      <template #emptyfiltered>
        <p class="my-2 text-center">{{ $T('EmptyFiltered') }}</p>
      </template>

      <template #cell(function)="data">
        <control-select :id="`${table.id}_UserFunction_${data.index}`" class="mb-0" size="sm" v-model="data.value.value" :options="data.value.options" :plaintext="noEdit" @change="$value => updateRelation(data.field.key, data.item, $value)"/>
      </template>

      <template #cell(role)="data">
        <control-select :id="`${table.id}_UserRole_${data.index}`" class="mb-0" size="sm" v-model="data.value.value" :options="data.value.options" :plaintext="data.item.isOwnRelation ||  noEdit" @change="$value => updateRelation(data.field.key, data.item, $value)"/>
      </template>

      <template #cell(isMainContact)="data">
        <control-checkbox :id="`${table.id}_UserIsMainContact_${data.index}`" class="mb-0" v-model="data.value" switch :disabled="noEdit" @change="$value => updateRelation(data.field.key, data.item, $value)"/>
      </template>

      <template #cell($decline)="data">
        <b-button variant="warning" size="sm" class="text-nowrap" @click="openModal('decline', data.item)"><b-icon icon="close"/> <slot name="declineButton"/></b-button>
      </template>

      <template #cell($accept)="data">
        <b-button variant="success" size="sm" class="text-nowrap" @click="acceptRelation(data.item)"><b-icon icon="check"/> <slot name="acceptButton"/></b-button>
      </template>

      <template #cell($kill)="data">
        <b-button variant="warning" size="sm" class="text-nowrap" @click="openModal('kill', data.item)"><b-icon icon="trash"/> <slot name="killButton"/></b-button>
      </template>

      <template #row-details="data">
        <template v-if="data.item.isTitle">
          <h3 class="h5">{{ data.item.title }}</h3>
        </template>
      </template>
    </b-table>

    <b-modal
      :id="confirmationModal.id"
      v-model="confirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
      title-tag="h4"
      title-class="align-self-center"
      hide-footer
    >
      <template #modal-header-close><b-icon icon="close" scale="0.6"/></template>
      <template #modal-title><slot name="modalTitle"/></template>

      <template v-if="confirmationModal.show">
        <div>
          <slot name="modalBody" :item="confirmationModal.item"/>
        </div>

        <div class="d-flex justify-content-end mt-3">
          <div class="btn-list">
            <b-button variant="light" size="sm" @click="closeModal"><slot name="modalAbortButton"/></b-button>
            <b-button variant="danger" size="sm" @click="killRelation"><b-icon icon="trash"/> <slot name="modalConfirmButton"/></b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { CLASS_TABLE_FIELD_KEY_ALL } from '@/assets/js/config/client'

import Table, { TableHelper } from '@/assets/js/helper/table'

export default {
  name: 'Locations.Users.RelationTable',
  props: {
    mode: {
      type: String,
      default: 'default',
      validator: value => ['default', 'relationrequest'].includes(value)
    },
    entityKey: {
      type: String,
      required: true
    },
    entityIdKey: {
      type: String,
      required: true
    },
    relationKey: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      default: ''
    },
    noEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      userId: this.$store.getters['profile/getUserId'],
      table: {
        id: `${this.$options.name}_${this.mode}`,
        helper: TableHelper,
        search: null,
        options: null,
        entityFilter: null
      },
      confirmationModal: {
        id: `${this.$options.name}_ConfirmationModal`,
        type: null,
        show: false,
        item: null
      }
    }
  },
  computed: {
    relationsIs () {
      return this.$store.getters[`${this.relationKey}/is`]
    },
    relationsDefinition () {
      return this.$store.getters[`${this.relationKey}/definition`]
    },
    relations () {
      return this.$store.getters[`${this.relationKey}/getUnwrapped`]
    },
    entityIds () {
      return this.relations.map(r => r[this.entityIdKey])
    },
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    definition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.entityKey}/getEntitiesUnwrapped`](this.entityIds)
    },
    tableEntities () {
      return this.relations
        .filter(this.table.entityFilter)
        .map(RELATION => {
          const ENTITY = this.entities.find(e => e.id === RELATION[this.entityIdKey]) || {}

          const FUNCTION_DEFINITION = this.relationsDefinition.$properties.function || {}
          const FUNCTION_OPTION_KEYS = (FUNCTION_DEFINITION.settings || {}).selectValues || []
          const FUNCTION_TRANSLATIONS = ((FUNCTION_DEFINITION.translations || {})[this.$store.getters['gui/getLanguage']] || {}).selectValues || []

          const ROLE_DEFINITION = this.relationsDefinition.$properties.role || {}
          const ROLE_OPTION_KEYS = (ROLE_DEFINITION.settings || {}).selectValues || []
          const ROLE_TRANSLATIONS = ((ROLE_DEFINITION.translations || {})[this.$store.getters['gui/getLanguage']] || {}).selectValues || []

          return {
            _entity: ENTITY,
            _relation: RELATION,
            id: ENTITY.id,
            isAccepted: RELATION.isAccepted,
            lastname: ENTITY.lastname,
            firstname: ENTITY.firstname,
            function: {
              value: RELATION.function,
              options: FUNCTION_OPTION_KEYS.map(oKey => ({ value: oKey, text: FUNCTION_TRANSLATIONS[oKey] }))
            },
            role: {
              value: RELATION.role,
              options: ROLE_OPTION_KEYS.map(oKey => ({ value: oKey, text: ROLE_TRANSLATIONS[oKey] }))
            },
            isMainContact: RELATION.isMainContact || false,
            isOwnRelation: RELATION[this.entityIdKey] === this.userId
          }
        })
    },
    tableData () {
      return new Table(this.tableEntities, this.table.options.fields)
    }
  },
  methods: {
    modesDefinition () {
      return {
        default: {
          search: {
            id: `${this.$options.name}_Default_Search`,
            value: '',
            on: ['lastname', 'firstname']
          },
          options: {
            fields: {
              includes: [].concat(this.noEdit ? [] : ['$kill']),
              excludes: [],
              sorting: ['lastname', 'firstname', 'function', 'role'].concat(this.noEdit ? [] : ['$kill']),
              sortable: [CLASS_TABLE_FIELD_KEY_ALL],
              label: {
                $kill: ''
              },
              variant: {},
              class: {
                isMainContact: 'cell-min cell-align-center',
                $kill: 'cell-min cell-align-right'
              },
              thClass: {},
              tdClass: {}
            }
          },
          entityFilter: r => r.isAccepted
        },
        relationrequest: {
          search: {
            id: `${this.$options.name}_RelationRequest_Search`,
            value: '',
            on: ['lastname', 'firstname']
          },
          options: {
            fields: {
              includes: [].concat(this.noEdit ? [] : ['$decline', '$accept']),
              excludes: [],
              sorting: ['lastname', 'firstname'].concat(this.noEdit ? [] : ['$decline', '$accept']),
              sortable: [CLASS_TABLE_FIELD_KEY_ALL],
              label: {
                $decline: '',
                $accept: ''
              },
              variant: {},
              class: {
                $decline: 'cell-min cell-align-right',
                $accept: 'cell-min cell-align-right'
              },
              thClass: {},
              tdClass: {}
            }
          },
          entityFilter: r => !r.isAccepted
        }
      }
    },
    setTableProperties () {
      const modeDefinition = this.modesDefinition()[this.mode]

      this.table.search = modeDefinition.search
      this.table.options = modeDefinition.options
      this.table.entityFilter = modeDefinition.entityFilter
    },
    setTableLabels () {
      if (this.definition.properties.length > 0 && this.relationsDefinition.properties.length > 0) {
        this.table.options.fields.label = Object.assign(this.table.options.fields.label, [].concat(this.definition.properties, this.relationsDefinition.properties).reduce((labels, p) => Object.assign(labels, { [p.name]: p.translations[this.$store.getters['gui/getLanguage']].name }), {}))
        this.$set(this.table.options.fields, 'labelsByDefinitionCreated', true)
      }
    },
    resetTableSearch () {
      this.table.search.value = ''
    },
    updateRelation (key, item, value) {
      if (!this.noEdit) this.$store.dispatch(`${this.relationKey}/updateEntity`, Object.assign({}, item._relation, { [key]: value }))
    },
    declineRelation () {
      this.$store.dispatch(`${this.relationKey}/removeEntity`, { id: this.confirmationModal.item._relation.id })
      this.closeModal()
    },
    acceptRelation (item = {}) {
      this.$store.dispatch(`${this.relationKey}/updateEntity`, Object.assign({}, item._relation, { isAccepted: true }))
    },
    killRelation () {
      this.$store.dispatch(`${this.relationKey}/removeEntity`, { id: this.confirmationModal.item._relation.id })
      this.closeModal()
    },
    openModal (type = '', item = {}) {
      this.confirmationModal.type = type
      this.confirmationModal.item = item
      this.confirmationModal.show = true
    },
    closeModal () {
      this.confirmationModal.type = null
      this.confirmationModal.show = false
      this.confirmationModal.item = null
    }
  },
  created () {
    this.setTableProperties()
    this.setTableLabels()
  },
  watch: {
    definition: {
      deep: true,
      handler () {
        this.setTableProperties()
        this.setTableLabels()
      }
    },
    relationsDefinition: {
      deep: true,
      handler () {
        this.setTableProperties()
        this.setTableLabels()
      }
    },
    noEdit () {
      this.setTableProperties()
    }
  }
}
</script>

<style lang="scss"></style>
